import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Menu from './Menu';
import './App.css';  // Asegúrate de incluir las animaciones CSS

function App() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();  // Prevenir recarga de la página al enviar el formulario
  
    try {
      // Realizar la solicitud de login
      await axios.post('https://app.marciglesias.dev/login', {
        username,
        password,
      }, {
        withCredentials: true,  // Esto asegura que la cookie se envíe con la solicitud
      });
  
      // Si el login es exitoso, navega al menú
      navigate('/menu');  // Navegación sin recargar la página
    } catch (error) {
      alert('Login failed');
    }
  };
  

  return (
    <div className="container">
      <div className="form-login">
        <h2>Login</h2>
        <form onSubmit={handleLogin}>
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            placeholder="Username"
          />
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
          />
          <button type="submit">Login</button>
        </form>
      </div>
    </div>
  );
}

// Asegúrate de que <App> esté envuelto por <Router>
export default function AppWrapper() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/menu" element={<Menu />} />
      </Routes>
    </Router>
  );
}
