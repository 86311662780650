import React, { useEffect, useState } from 'react';
import axios from 'axios';  // Importamos axios para verificar el token
import './Menu.css';

function Menu() {
  const [auth, setAuth] = useState(false);  // Estado para verificar si el usuario está autenticado

  // Este hook verifica si el token es válido al cargar el componente
  useEffect(() => {
    // Hacemos una solicitud GET para verificar si el token es válido en el VPS
    axios
      .get('https://app.marciglesias.dev/verify', { withCredentials: true })  // Usamos withCredentials para enviar cookies
      .then(() => {
        setAuth(true);  // Si el token es válido, autorizamos el acceso
      })
      .catch(() => {
        setAuth(false);  // Si el token es inválido, redirigimos al login
        //window.location.href = '/';
        console.log('Te has cagado');
      });
  }, []);

  // Si no está autorizado, mostramos una pantalla de "cargando"
  if (!auth) {
    return <div>Loading...</div>;
  }

  // Si el token es válido, mostramos el menú con enlaces a las aplicaciones
  return (
    <div className='container-menu'>
      <h2>Application Menu</h2>
      <section className='menu-apps'>
        <div>
          <a href="https://todo.marciglesias.dev" target="" rel="noopener noreferrer">
            Todo List
          </a>
        </div>
        <div>
          <a href="https://controlgastos.marciglesias.dev" target="" rel="noopener noreferrer">
            Control de Gastos
          </a>
        </div>
        <div>
          <a href="https://controlpeso.marciglesias.dev" target="" rel="noopener noreferrer">
            Control de Peso
          </a>
        </div>
      </section>
    </div>
  );
}

export default Menu;
